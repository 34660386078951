(function () {
	'use strict';
	angular.module('ev.directives', ['ngAnimate'])
		.directive("noNgAnimate", function ($animate) {
			return function (scope, element) {
				$animate.enabled(element, false);
			};
		})
		.directive("evLoader", function () {
			return {
				restrict: "E",
				replace: true,
				scope: {
					loading: "=",
					game: "="
				},
				templateUrl: '/assets/views/frontend/partials/loader.html'
			}
		})
		.directive("evPatreonC2a", function(){
			return {
				restrict: "EA",
				template: '<div><span>We have ads enabled to help us cover running costs.</span><br /><span>Would you mind turning off your ad blocker?</span></div><div class="text-center">or support us and<br /><a href="https://patreon.com/Eventvods" class="patreon-c2a" target="_blank"><img src="/assets/images/patreon.png" class="become-patron" /></a></div>'
			}
		})
		.directive('uniqueEmail', ['$http', '$q', 'API_BASE_URL', function($http, $q, API){
			return {
				require: 'ngModel',
				link: function(scope, elem, attr, ctrl) {
					ctrl.$asyncValidators.uniqueEmail = function(email) {
						if (ctrl.$isEmpty(email)) {
							return $q.resolve();
						}
						var q = $q.defer();
						$http.get(API+'/validate/email/'+email)
						.then(function(){
							q.resolve();
						}, function(){
							q.reject();
						});
						return q.promise;
					};
				}
			 };
		}])
		.directive('uniqueName', ['$http', '$q', 'API_BASE_URL', function($http, $q, API){
			return {
				require: 'ngModel',
				link: function(scope, elem, attr, ctrl) {
					ctrl.$asyncValidators.uniqueName = function(name) {
						if (ctrl.$isEmpty(name)) {
							return $q.resolve();
						}
						var q = $q.defer();
						$http.get(API+'/validate/displayName/'+name)
						.then(function(){
							q.resolve();
						}, function(){
							q.reject();
						});
						return q.promise;
					};
				}
			 };
		}])
		.directive("evRatings", function () {
			return {
				restrict: "E",
				replace: true,
				scope: {
					"ngModel": "=",
					"ngSubmit": "&?",
					"match": "@?",
					"index": "@?",
				},
				link: function ($scope, $elem, $attrs) {
					$scope.readonly = ($attrs.readonly == "true");
					$scope.hover = null;

					$scope.getClass = function ($index) {

						var star, value = $scope.hover || $scope.ngModel;
						value = (Math.floor(value * 2) / 2).toFixed(1);
						
						if (value >= $index)
							star = "fa-star";
						else if (value >= ($index - 0.5) && value < $index)
							star = "fa-star-half-o";
						else
							star = "fa-star-o";
						return star;
					}

					$scope.onHover = function (rating) {
						if($scope.readonly) return;
						$scope.hover = rating;
					}
					$scope.rate = function (rating) {
						if($scope.readonly) return;
						$scope.ngModel = rating;
						$scope.ngSubmit({"matchId": $scope.match, "index": $scope.index, "rating": rating});
					}
				},
				templateUrl: '/assets/views/frontend/partials/ratings.html'
			}
		})
		.directive("vodPlaceholder", function () {
			return {
				restrict: "E",
				replace: true,
				template: '<span class="mb-1 mt-2 my-md-0 opacity-text">Soon™</span>'
			}
		})
		.directive("extrasPlaceholder", function () {
			return {
				restrict: "E",
				replace: true,
				template: '<span class="mb-1 mt-2 my-md-0 opacity-text"> - </span>'
			}
		})
})();
