(function () {
	'use strict';
	angular.module('eventvods')
		.controller('FBPlayerController', ['API_BASE_URL', '$rootScope', '$http', '$routeParams', '$timeout', '$window', '$interval', '$location',
			function (API, $rootScope, $http, $routeParams, $timeout, $window, $interval, $location) {
				var vm = this,
					player = null,
					$player = $('#player'),
					$frame,	startAt, playAll;

				vm.setup = {
					loading: true,
				};

				vm.gameIndex = parseInt($routeParams.game || 1) - 1;
				// checks if autoplay param is defined, otherwise false.
				// converts to truthy value using ~str.indexOf and finally bool using !!
				playAll = !!~($routeParams.autoplay || "false").indexOf('true');
				startAt = $routeParams.start || "picksBans";

				switch(startAt.toLowerCase()){
					case "gamestart":
					case "game":
						startAt = "gameStart";
						break;
					default:
						startAt = "picksBans";
						break;
				}

				vm.start = function(){
					player.seek(0);
				}

				vm.draft = function(){
					var url = vm.data.data[vm.gameIndex].facebook.picksBans;
					if(url.indexOf('ev.wtf') > -1){
						$http.get(url.replace('http:','https:')+'?json=true')
						.then(function(response){
							var start = parseFacebook(response.data.link);
							if (!start) return displayError("Invalid video URL.", true);
							vm.setup.time = start.time;
							if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
							else player.seek(vm.setup.time);
						})
						return;
					}
					var start = parseFacebook(url);
					if (!start) return displayError("Invalid video URL.", true);
					vm.setup.time = start.time;
					if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
					else player.seek(vm.setup.time);
				}
				vm.gameStart = function(){
					var url = vm.data.data[vm.gameIndex].facebook.gameStart;
					if(url.indexOf('ev.wtf') > -1){
						$http.get(url.replace('http:','https:')+'?json=true')
						.then(function(response){
							var start = parseFacebook(response.data.link);
							if (!start) return displayError("Invalid video URL.", true);
							vm.setup.time = start.time;
							if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
							else player.seek(vm.setup.time);
						})
						return;
					}
					var start = parseFacebook(url);
					if (!start) return displayError("Invalid video URL.", true);
					vm.setup.time = start.time;
					if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
					else player.seek(vm.setup.time);
				}
				vm.gotoGame = function(index){
					vm.gameIndex = index;
					$location.search('game', (vm.gameIndex+1));
					if (vm.data.data[vm.gameIndex].placeholder) return displayError("Match complete - this is is just included as a placeholder to prevent spoilers.", true);
					var url = vm.data.data[vm.gameIndex].facebook[startAt];
					if(url.indexOf('ev.wtf') > -1){
						$http.get(url.replace('http:','https:')+'?json=true')
						.then(function(response){
							var start = parseFacebook(response.data.link);
							if (!start) return displayError("Invalid video URL.", true);
							$rootScope.$emit('setWatched', vm.data._id, vm.gameIndex);
							vm.setup.time = start.time;
							if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
							else player.seek(vm.setup.time).play();

						})
						return;
					}
					var start = parseFacebook(url);
					if (!start) return displayError("Invalid video URL.", true);
					$rootScope.$emit('setWatched', vm.data._id, vm.gameIndex);
					vm.setup.time = start.time;
					if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
					else player.seek(vm.setup.time).play();
				}
				vm.nextGame = function(){
					vm.gotoGame(vm.gameIndex + 1);
				}
				$rootScope.$on('$routeUpdate', function(){
					var newIndex = parseInt($routeParams.game || 1) - 1;
					if(newIndex == vm.gameIndex) return;
					delete vm.setup.error;
					vm.gotoGame(newIndex);
				})
				function displayError(message, stopVideo){
					vm.setup.loading = false;
					vm.setup.error = message;
					if(stopVideo) player.pauseVideo();
					console.error(message);
				}
				function playerSize() {
					$timeout(function () {
						var maxH = window.innerHeight - (100 + 70);
						var dWidth = window.innerWidth;
						var maxW = maxH * 16/9;
						if(dWidth < maxW) maxW = dWidth;
						vm.setup.width = maxW;
						vm.setup.height = maxW * 9/16;
						$('#player').css('width', vm.setup.width).css('height', vm.setup.height);
					}, 0);
				};

				function parseFacebook(link) {
					var fb = /(https?:\/\/(?:www.)?facebook.com\/(?:\S+)\/videos\/(?:\d+))\?t=(\d+)s/i.exec(link);
					if (fb == null) return false;
					else return {
						vid: fb[1],
						time: parseInt(fb[2])
					}
				}
				function initPlayer() {
					$rootScope.$emit('setWatched', vm.data._id, vm.gameIndex);
					$('.video-container').addClass('fb-video');
					vm.setup.loading = false;
					angular.element(document).duScrollTo(0, 100, 1000);
					$timeout(function(){
						$window.FB.Event.subscribe('xfbml.ready', function(msg) {
							if (msg.type === 'video') {
								player = msg.instance;
								$timeout(function(){
									player.seek(vm.setup.time);
								}, 250);
							}
						});
						$window.FB.XFBML.parse();
					}, 0);
				}
				function switchVideo(video, time){
					vm.setup.video = video;
					vm.setup.time = time;
					initPlayer();
				}

				playerSize();

				$(window).on("resize orientationchange ", playerSize);
				$http.get(API + '/match/' + $routeParams.match)
					.then(function (res) {
						vm.data = res.data;
						$rootScope.oldGame = $rootScope.game || null;
						$rootScope.game = $routeParams.gameslug;
						document.title = $rootScope.meta.title = vm.data.team1.name + ' vs ' + vm.data.team2.name + ' - ' + vm.data.event.shortTitle + (vm.data.event.subtitle ? ', ' + vm.data.event.subtitle : '') + ' - Eventvods';
						$rootScope.meta.description = "Watch " + vm.data.team1.name + " vs " + vm.data.team2.name + " during " + vm.data.event.shortTitle + (vm.data.event.subtitle ? ', ' + vm.data.event.subtitle : '') + ". Watch all esports matches easily and spoiler-free on Eventvods.";
						var url = vm.data.data[vm.gameIndex].facebook[startAt];
						//Temporary setup
						if(url.indexOf('ev.wtf') > -1){
							$http.get(url.replace('http:','https:')+'?json=true')
							.then(function(response){
								var start = parseFacebook(response.data.link);
								if (!start) return displayError("Unable to parse that video URL.");
								vm.setup.video = start.vid;
								vm.setup.time = start.time;
								initPlayer();
							})
							return;
						}
						var start = parseFacebook(url);
						if (!start) return displayError("Unable to parse that video URL.");
						vm.setup.video = start.vid;
						vm.setup.time = start.time;
						initPlayer();
						$timeout(function(){
							$window.prerenderReady = true;
						}, 1);
					}, function (err) {
						displayError(err.message);
						$rootScope.meta.status = 404;
					});

			}
		]);
}());
