(function () {
	'use strict';
	angular.module('eventvods')
		.controller('RegisterController', ['SessionManager', '$location', '$rootScope', '$timeout', '$analytics',
			function (SessionManager, $location, $rootScope, $timeout, $analytics) {
				var vm = this;
				vm.data = {};
				$rootScope.oldGame = $rootScope.game;
				$rootScope.game = 'static';

				function getSession(){
					vm.session = SessionManager.get();
					if(vm.session === null) return $timeout(getSession, 1000);
					if(vm.session !== false) $location.path('/account').search({});
					else $('[data-toggle="tooltip"]').tooltip();
				}
				getSession();

				vm.register = function (form) {
					$analytics.eventTrack('registerFormSubmitted', {  category: 'Registration'});
					$('#registerForm input').addClass('ng-touched');
					if (form.$valid)
						SessionManager.register(vm.data)
							.then(function () {
								$analytics.eventTrack('registerFormSuccess', {  category: 'Registration'});
								$location.path('/account').search({});
							});
					else
						$analytics.eventTrack('registerFormFailed', {  category: 'Registration', label: 'Pre-submission validation fail' });
				};
			}
		]);
}());
